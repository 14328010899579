<template>
  <v-card
    height="100%"
    color="backgroundColor"
  >
    <DesaffectationTab />
  </v-card>
</template>

<script>
import DesaffectationTab from "@/components/DesaffectationComponent/DesaffectationTab.vue";

export default {
  components: {
    DesaffectationTab
  },
};
</script>
