<template>
  <v-card
      class="ma-4 pa-4"
      min-height="50%"
  >
    <template>
      <v-toolbar flat>
        <v-spacer/>
        <BooksTab
            action="Ajouter"
            class="ma-auto"
            :userBooks="additionalsBooks"
            @delete="catchDeleteEmit"
            @add="catchAddEmit"
            editeur=""
        />
      </v-toolbar>
    </template>
    <div class="d-flex justify-center ma-4">
      <v-progress-circular
          v-if="loading"
          :size="50"
          color="primary"
          indeterminate
          justify-center
      ></v-progress-circular>
    </div>
    <div v-if="treeviewdata.length === 0 && !loading">
      <h3 class="text-center">L'établissement n'a pas de devis numérique dans Divalto.</h3>
    </div>
    <v-treeview
        ref="tree"
        :items="treeviewdata"
        v-model="books"
        hoverable
        return-object
        @input="catchInputEmit"
        selectable
        class="ma-4"
        v-else-if="!loading"
    >
      <template v-slot:prepend="{ item }">
        <v-icon
            color="primary"
            v-if="item.type === 'lot'"
        >
          mdi-package-variant
        </v-icon>
        <v-img
            height="100px"
            width="80px"
            aspect-ratio="3/4"
            v-if="item.type === 'livre' && item.imgCouverture && $vuetify.breakpoint.smAndUp"
            :src="item.imgCouverture"
            class="ma-2"
        ></v-img>
        <v-icon
            color="primary"
            v-if="item.type === 'livre' && !item.imgCouverture "
        > mdi-book
        </v-icon>
        <v-icon
            color="primary"
            v-if="item.type === 'devis'"
        >
          mdi-school
        </v-icon>
      </template>
      <template v-slot:label="{ item }">
        <span v-if="item.type ===`livre`">
          <span class="primary--text">Titre :
            <span class="secondary--text">
              {{ item.name }}
            </span>
          </span>
          <br>
          <span class="primary--text caption">Ref. Divalto : {{ item.refOffreDivalto }}</span><br>
          <span
              class="primary--text caption"
              v-if="item.quantity !== -1"
          >Editeur : {{ item.editeur }}</span><br v-if="item.quantity !== -1">
          <span class="primary--text caption">Matière : {{ item.matiere }}</span><br>
          <v-divider>
          </v-divider>
        </span>
        <span
            v-else
            class="primary--text"
        >{{ item.name }}</span>
      </template>
    </v-treeview>

  </v-card>
</template>

<script>
import BooksTab from "@/components/Utility/BooksTab.vue";

export default {
  name: "BookListDesaffectation",
  components: {
    BooksTab,
  },
  props: {
    devis: {
      type: Object,
      required: true,
    },
    classe: {
      type: Array,
      required: true,
    },
  },
  computed: {
    classes() {
      return this.devis.classes;
    },
    treeviewdata() {
      let id = 0;
      let newClasses = [];
      let oldetab = this.classes;
      oldetab.forEach((classe) => {
        if (this.classe.includes(classe.name) || classe.name === 'LIVRES ADDITIONNELS') {
          id++;
          let children = [];
          let name = classe.name;
          let type = classe.type;
          classe.lots.forEach((lot) => {
            let children2 = [];
            let lotid = lot.id;
            let name = lot.name;
            let type = lot.type;
            lot.livres.forEach((livre) => {
              livre.id = id;
              id++;
              children2.push(livre);
            });
            children.push({ id: lotid, name, type, children: children2 });
          });
          newClasses.push({ id, type, name, children });
        }
      });
      return newClasses;
    },
  },
  data() {
    return {
      loading: false,
      divaltoDown: false,
      additionalsBooks: [],
      key: 0,
      books: [],
    }
  },
  watch: {
    additionalsBooks() {
      this.key ++;
    }
  },
  methods: {
    catchDeleteEmit(payload) {
      this.additionalsBooks.splice(
          this.additionalsBooks.findIndex((v) => v.ean === payload.book.ean),
          1
      );
    },
    catchAddEmit(payload) {
      let book = {
        id: payload.book.ouvrage.ean,
        name: payload.book.ouvrage.nom,
        type: "livre",
        editeur: payload.book.ouvrage.editeurCatalog.codeEditeur,
        codeEditeur: payload.book.codeEditeur,
        ean: payload.book.ouvrage.ean,
        refOffreDivalto: payload.book.refOffreDivalto,
        imgCouverture: payload.book.ouvrage.imgCouverture,
        matiere: payload.book.ouvrage.matiere,
        prix: payload.book.prixTtc,
        quantity: 0,
      };
      this.additionalsBooks.push(book);
      this.$emit("additionalsBooks", this.additionalsBooks);
    },

    aditionnalClasse() {
      let aditionnalClasse = {
        id: 0,
        name: "LIVRES ADDITIONNELS",
        type: "devis",
        lots: [
          {
            id: 0,
            name: "LOTS ADDITIONNELS",
            type: "lot",
            livres: this.additionalsBooks,
          },
        ],
      };
      return aditionnalClasse;
    },

    catchInputEmit(payload) {
      this.$emit("input", payload);
    },
  }
}
</script>